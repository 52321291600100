






















import {Component, Vue} from 'vue-property-decorator';
import DashboardTile from '@/models/DashboardTile';
import CleanTimeRepository from '@/api/repositories/CleanTimeRepository';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import AnalyticsRepository from '@/api/repositories/AnalyticsRepository';
import Company from '@/models/Company';
import {namespace} from 'vuex-class';

const CompanyStore = namespace('company');

@Component({
    components: {
        DashboardTileComponent: () => import (
            /* webpackChunkName: "DashboardTileComponent" */
            '@/components/shared/DashboardTile.component.vue'),
        DashboardChartComponent: () => import (
            /* webpackChunkName: "DashboardChartComponent" */
            '@/components/shared/DashboardChart.component.vue'),
    },
})
export default class CompanyDashboardComponent extends Vue {
    @CompanyStore.Getter('activeCompany')
    private _company!: Company;

    public dashboardTiles: any = [];
    public dashboardData: any = null;

    public async created() {
        const analyticsRepository: AnalyticsRepository = RepositoryFactory.get('analytics');

        // RN-380 - see getDashboardData for further information about improving loading speed!
        this.dashboardData = await analyticsRepository.getDashboardData(this.company!.id);

        this.dashboardTiles = [
            [
                {
                    title: `${this.$t('CUSTOMER_DASHBOARD.TILES.TOTAL_CUSTOMER')}`,
                    amount: this.dashboardData.customerCount,
                    icon: 'mdi-domain',
                    backgroundColor: 'total-customer',
                    route: 'customersOverview',
                },
                {
                    title: `${this.$t('CUSTOMER_DASHBOARD.TILES.TOTAL_EMPLOYEE')}`,
                    amount: this.dashboardData.userCount,
                    icon: 'people',
                    backgroundColor: 'total-employee',
                    route: 'usersOverview',
                },
            ], [
                {
                    title: `${this.$t('CUSTOMER_DASHBOARD.TILES.TOTAL_LOCATION')}`,
                    amount: this.dashboardData.locationCount,
                    icon: 'room',
                    backgroundColor: 'total-location',
                    route: null,
                },
                {
                    title: `${this.$t('CUSTOMER_DASHBOARD.TILES.TOTAL_WORKTIME')}`,
                    amount: this.dashboardData.workSessionCount,
                    icon: 'assignment',
                    backgroundColor: 'total-workTime',
                    route: 'jobsOverview',
                },
            ],
        ];
    }

    get company(): Company {
        return this._company;
    }
}
